import { Icon, Text } from 'fitify-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'

import AnimatedTextWord from '../../animations/WordAnimation'

import {
  StyledHeaderBackground,
  StyledHeaderBackgroundGradient,
  StyledHeaderContainer,
  StyledHeaderDesktop,
  StyledHeaderWrapper,
  StyledHeaderWrapperContent,
  StyledScrollDiv,
  StyledScrollLink,
} from './Header.Styled'
import { HeaderProps } from './Header.Types'
import { HeaderRating } from './HeaderRating'
import { useHeader } from './useHeader'

export const DesktopHeader = ({
  button,
  headerImages,
  headline,
  shouldShowRating,
  subheadline,
  variant,
}: HeaderProps) => {
  const { t } = useTranslation()
  const {
    currentImage,
    currentImageIndex,
    handleScrollToAnimation,
    isDigital,
    isImageSliderMounted,
  } = useHeader({ headerImages })

  return (
    <StyledHeaderDesktop>
      <StyledHeaderContainer>
        <StyledHeaderWrapper>
          <StyledHeaderWrapperContent>
            <AnimatedTextWord
              text={t(headline)}
              variant={variant as TextTypesVariants}
            />

            <Text as={'p'} variant={'header-paragraph'}>
              {t(subheadline)}
            </Text>

            {button ? button : null}

            {shouldShowRating ? <HeaderRating variant="desktop" /> : null}
          </StyledHeaderWrapperContent>
        </StyledHeaderWrapper>

        <StyledScrollDiv>
          <StyledScrollLink
            onClick={handleScrollToAnimation}
            aria-label={
              isDigital
                ? t('aria_label_scroll_down')
                : t('hc_aria_label_scroll_down')
            }
          >
            <Icon name={'arrow-down'} />
          </StyledScrollLink>
        </StyledScrollDiv>
      </StyledHeaderContainer>
      <StyledHeaderBackground>
        <StyledHeaderBackgroundGradient />
        <AnimatePresence>
          <motion.img
            key={'headerImage_' + currentImageIndex}
            initial={isImageSliderMounted ? { opacity: 0 } : {}}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              minHeight: '100vh',
            }}
            src={currentImage.headerImage}
            srcSet={currentImage.headerImageSet}
            alt={`Header Image - ${currentImageIndex}}`}
          />
        </AnimatePresence>
      </StyledHeaderBackground>
    </StyledHeaderDesktop>
  )
}
