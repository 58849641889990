import { TextStyles } from 'fitify-ui'
import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledAppsLink = styled.a`
  margin-right: 18px;
  margin-bottom: 18px;

  ${phone(css`
    margin-right: 9px;
    margin-left: 9px;
  `)}
`
export const StyledApps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const StyledAppsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 18px;
`
export const StyledAppsTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;

  ${TextStyles} {
    text-align: center;
  }

  ${phone(css`
    ${TextStyles} {
      font-size: 40px;
      word-break: break-word;
    }
  `)}
`
