import { colors, StyledIcon } from 'fitify-ui'
import { BREAKPOINTS } from 'fitify-ui-landing/src/theme/breakpoints'
import styled from 'styled-components'

export const StyledTool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${StyledIcon} {
    width: 40px;
    height: 40px;
  }
`
export const StyledToolIconWrapper = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  background: ${colors.Blue50};
  border-radius: 50%;
  margin-bottom: 12px;

  ${StyledIcon} {
    width: 40px;
    height: 40px;
  }
`
export const StyledTools = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 24px 56px;
  background: ${colors.White};
  border: 1px solid ${colors.Gray200};
  border-radius: 24px;

  @media ${BREAKPOINTS.SM.lte} {
    padding: 24px;
  }
`
export const StyledToolsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 32px;
  margin-top: 40px;

  ${StyledTool} {
    flex: 1 0 16.6%;

    @media ${BREAKPOINTS.MD.lte} {
      flex: 1 0 33.3%;
    }

    @media ${BREAKPOINTS.SM.lte} {
      flex: 1 0 50%;
    }
  }
`
