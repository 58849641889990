import Image from 'next/legacy/image'

import {
  StyledRatingHeader,
  StyledRatingHeaderItem,
} from './HeaderRating.Styled'

type HeaderRatingProps = {
  variant: 'desktop' | 'mobile'
}

export const HeaderRating = ({ variant }: HeaderRatingProps) => {
  return (
    <StyledRatingHeader>
      <StyledRatingHeaderItem>
        <Image
          priority
          src={'/assets/images/icons/app-of-the-day.svg'}
          width={variant === 'desktop' ? 163 : 143}
          height={variant === 'desktop' ? 38 : 32}
          alt={`Fitify.com - App of the Day`}
        />
      </StyledRatingHeaderItem>
      <StyledRatingHeaderItem>
        <Image
          priority
          src={'/assets/images/icons/rating.svg'}
          width={variant === 'desktop' ? 163 : 143}
          height={variant === 'desktop' ? 38 : 32}
          alt={`Fitify.com - Rating`}
        />
      </StyledRatingHeaderItem>
    </StyledRatingHeader>
  )
}
