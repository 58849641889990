import { Text } from 'fitify-ui'
import { motion } from 'framer-motion'

interface AnimatedTextWordProps {
  text: string
  initialDelay?: number
  variant: TextTypesVariants
}

const AnimatedTextWord = ({
  text,
  initialDelay = 0,
  variant,
}: AnimatedTextWordProps) => {
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.12,
        delayChildren: initialDelay + 0.04 * i,
      },
    }),
  }

  const child = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: 20,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  }

  return (
    <motion.div
      style={{
        flexWrap: 'wrap',
        display: 'flex',
      }}
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <motion.span variants={child} style={{ marginRight: '12px' }}>
        <Text as={'h1'} variant={variant} html={text} />
      </motion.span>
    </motion.div>
  )
}

export default AnimatedTextWord
