import { AnimatePresence, useMotionValueEvent, useScroll } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useRef, useState } from 'react'

import {
  StyledAnimationImageWrapper,
  StyledAnimationWrapper,
  StyledImageContainer,
  StyledMotionImage,
  StyledScrollComponentContainer,
} from './MobileAnimation.Styled'
import MobileAnimationText from './MobileAnimationText'

const FEATURE_HEIGHT = 80 // in VH
const PHONE_IMAGE_HEIGHT = 584
const PHONE_IMAGE_WIDTH = 302

const MobileAnimation = ({
  features,
}: {
  features: {
    imageSrc: string
    imageSrcSet: string
    text: string
  }[]
}) => {
  const { i18n } = useTranslation()

  const mobileAnimationRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: mobileAnimationRef,
    offset: ['start center', 'end center'],
  })
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const currentImage = features[currentImageIndex]

  // Image localization
  const languagePattern = /\{\{language\}\}/g
  const localizedSrc = currentImage.imageSrc.replace(
    languagePattern,
    i18n.language
  )
  const localizedSrcSet = currentImage.imageSrcSet.replace(
    languagePattern,
    i18n.language
  )

  // calculate visibility positions for each feature
  const visibilityPositions = useMemo(() => {
    const imageVisibilityCoef = 100 / features.length

    return Array.from(
      { length: features.length },
      (_, i) => i * imageVisibilityCoef
    )
  }, [features])

  // calculate container height - 70vh for each feature
  const calculatedContainerHeight = useMemo(() => {
    return features.length * FEATURE_HEIGHT
  }, [features])

  // get visible image index by scroll position
  const getVisibleImage = useCallback(
    (
      scrollPosition: number // get visible image index by scroll position
    ) => {
      for (let i = 1; i < visibilityPositions.length; i++) {
        if (scrollPosition <= visibilityPositions[i]) {
          return i - 1
        }
      }

      // if scroll position is bigger than last visibility position
      return visibilityPositions.length - 1
    },
    [visibilityPositions]
  )

  useMotionValueEvent(scrollYProgress, 'change', (currentPosition) => {
    const scrollPosition = Math.ceil(currentPosition * 100) // scroll position in percent
    const visibleImageIndex = getVisibleImage(scrollPosition)

    if (visibleImageIndex >= 0 && visibleImageIndex !== currentImageIndex) {
      setCurrentImageIndex(visibleImageIndex)
    }
  })

  return (
    <StyledScrollComponentContainer
      id={'mobile-animation'}
      ref={mobileAnimationRef}
      height={calculatedContainerHeight}
    >
      <StyledAnimationWrapper>
        {features.map(({ text }, index) => (
          <MobileAnimationText
            key={index}
            text={text}
            contentHeight={FEATURE_HEIGHT}
          />
        ))}
      </StyledAnimationWrapper>

      <StyledAnimationImageWrapper>
        <StyledImageContainer width={PHONE_IMAGE_WIDTH} height={FEATURE_HEIGHT}>
          <AnimatePresence>
            <StyledMotionImage
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              key={'phoneImage_' + currentImageIndex}
              src={localizedSrc}
              srcSet={localizedSrcSet}
              width={PHONE_IMAGE_WIDTH}
              height={PHONE_IMAGE_HEIGHT}
              alt={`${currentImageIndex} - Human Coaching Screen`}
            />
          </AnimatePresence>
        </StyledImageContainer>
      </StyledAnimationImageWrapper>
    </StyledScrollComponentContainer>
  )
}

export default MobileAnimation
