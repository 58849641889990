import { DataTypes } from 'fitify-ui-landing/src/@types'

const toolsData: DataTypes.ToolItem[] = [
  {
    icon: 'kettlebell-tool',
    title: 'tool_kettlebell',
  },
  {
    icon: 'trx-tool',
    title: 'tool_trx_v2',
  },
  {
    icon: 'bosu-tool',
    title: 'tool_bosu',
  },
  {
    icon: 'resistanceband-tool',
    title: 'tool_resistanceband',
  },
  {
    icon: 'foamroller-tool',
    title: 'tool_foamroller',
  },
  {
    icon: 'medicineball-tool',
    title: 'tool_medicineball',
  },
  {
    icon: 'swissball-tool',
    title: 'tool_swissball',
  },
  {
    icon: 'pullupbar-tool',
    title: 'tool_pullupbar',
  },
  {
    icon: 'dumbbell-tool',
    title: 'tool_dumbbell',
  },
  {
    icon: 'barbell-tool',
    title: 'tool_barbell',
  },
  {
    icon: 'sandbag-tool',
    title: 'tool_sandbag',
  },
  {
    icon: 'latstation-tool',
    title: 'tool_gymmachines',
  },
]

export { toolsData }
