import { DesktopHeader } from './DesktopHeader'
import { HeaderProps } from './Header.Types'
import { MobileHeader } from './MobileHeader'

const Header = ({
  headerImages,
  headline,
  subheadline,
  variant,
  button,
  shouldShowRating,
}: HeaderProps) => {
  return (
    <>
      <MobileHeader
        headerImages={headerImages}
        headline={headline}
        subheadline={subheadline}
        variant={variant}
        button={button}
        shouldShowRating={shouldShowRating}
      />
      <DesktopHeader
        headerImages={headerImages}
        headline={headline}
        subheadline={subheadline}
        variant={variant}
        button={button}
        shouldShowRating={shouldShowRating}
      />
    </>
  )
}

export default Header
