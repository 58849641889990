import { motion, useScroll, useTransform } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useRef } from 'react'

import {
  StyledImageWrapper,
  StyledText,
  StyledTextWrapper,
} from './MobileAnimation.Styled'

const MobileAnimationText = ({
  text,
  contentHeight,
}: {
  text: string
  contentHeight: number
}) => {
  const { t } = useTranslation()
  const textRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ['start end', 'end start'],
  })

  const opacity = useTransform(
    scrollYProgress,
    // Scroll positions of current container
    [0.25, 0.5, 0.75],
    // Into opacity values
    [0, 1, 0]
  )

  return (
    <StyledImageWrapper ref={textRef} height={contentHeight}>
      <motion.div
        style={{
          opacity: opacity,
        }}
      >
        <StyledTextWrapper className="target">
          <StyledText dangerouslySetInnerHTML={{ __html: t(text) }} />
        </StyledTextWrapper>
      </motion.div>
    </StyledImageWrapper>
  )
}

export default MobileAnimationText
