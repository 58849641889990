import { LocaleConfigItem } from 'fitify-ui-landing/src/@types/ConfigTypes'
import { useRouter } from 'next/router'
import { type NextSeoProps } from 'next-seo'
import { useMemo } from 'react'

import { getCanonicalUrl } from '@/utils/seo'

import localeConfig from '../../config/localeConfig.json'

const supportedLocales: LocaleConfigItem[] = localeConfig

/**
 * This hook returns alternative language link tags for SEO purposes.
 * For details see https://developers.google.com/search/docs/specialty/international/localized-versions?hl=en#html
 */
export const useHreflangTags = (): Pick<NextSeoProps, 'languageAlternates'> => {
  const router = useRouter()

  return useMemo(() => {
    return {
      languageAlternates: [
        ...supportedLocales.map((locale) => {
          return {
            hrefLang: locale.origin,
            href: getCanonicalUrl(router.asPath, locale.origin),
          }
        }),
        {
          hrefLang: 'x-default',
          href: getCanonicalUrl(router.asPath),
        },
      ],
    }
  }, [router.asPath])
}
