import { useEffect, useState } from 'react'

// Custom hook for creating an image slider
function useImageSlider(images: any[], intervalTime = 6000): number {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const imagesCount = images.length

    // Set up an interval to change the current image index
    const interval = setInterval(() => {
      setCurrentImageIndex((prevCount) => (prevCount + 1) % imagesCount)
    }, intervalTime)

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(interval)
    }
  }, [images, intervalTime])

  return currentImageIndex
}

export default useImageSlider
