import styled, { css } from 'styled-components'

import { biggerPhoneLandscape, phone } from '../../../theme/breakpoints'

export const StyledRatingHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: start;
  bottom: 120px;
  margin-bottom: 80px;

  ${biggerPhoneLandscape(css`
    position: relative;
    bottom: unset;
  `)};

  ${phone(css`
    position: relative;
    bottom: unset;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 0;
    order: 5;
  `)};
`

export const StyledRatingHeaderItem = styled.div`
  margin-top: 48px;

  &:nth-child(1) {
    order: 1;
  }

  &:nth-child(2) {
    order: 2;
    margin-left: 14px;
    margin-top: 48px;
  }

  ${biggerPhoneLandscape(css`
    &:nth-child(n) {
      margin-top: 24px;
    }
  `)};

  ${phone(css`
    &:nth-child(n) {
      margin-top: 0;
    }
  `)};
`
