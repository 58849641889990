import { Icon, Text } from 'fitify-ui'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import {
  StyledHeaderContainer,
  StyledHeaderMobile,
  StyledHeaderWrapper,
  StyledHeaderWrapperContent,
  StyledMobileImage,
  StyledScrollDiv,
  StyledScrollLink,
} from './Header.Styled'
import { HeaderProps } from './Header.Types'
import { HeaderRating } from './HeaderRating'
import { useHeader } from './useHeader'

export const MobileHeader = ({
  button,
  headerImages,
  headline,
  shouldShowRating,
  subheadline,
  variant,
}: HeaderProps) => {
  const { t } = useTranslation()
  const { currentImage, currentImageIndex, isDigital, isImageSliderMounted } =
    useHeader({ headerImages })

  return (
    <StyledHeaderMobile>
      <StyledHeaderContainer>
        <StyledHeaderWrapper>
          <StyledHeaderWrapperContent>
            <>
              <StyledMobileImage>
                <AnimatePresence>
                  <motion.div
                    key={'headerImageDiv_' + currentImageIndex}
                    initial={isImageSliderMounted ? { opacity: 0 } : {}}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                  >
                    {currentImage.chatImage ? (
                      <motion.div
                        key={'chatImageDiv_' + currentImageIndex}
                        initial={
                          isImageSliderMounted ? { opacity: 1, left: -200 } : {}
                        }
                        animate={{ opacity: 1, left: 16 }}
                        exit={{ opacity: 1, left: -200 }}
                        transition={{ duration: 1 }}
                        style={{
                          width: '30%',
                          height: '60%',
                          position: 'absolute',
                          top: '16px',
                          left: '16px',
                          zIndex: '1000',
                        }}
                      >
                        <Image
                          style={{ zIndex: 100 }}
                          priority
                          key={'chatImage_' + currentImageIndex}
                          src={currentImage.chatImage}
                          layout={'fill'}
                          objectFit="contain"
                          alt={`Chat Image - ${currentImageIndex}`}
                        />
                      </motion.div>
                    ) : null}

                    <Image
                      priority
                      key={'headerImage_' + currentImageIndex}
                      src={currentImage.headerImage}
                      layout={'fill'}
                      objectFit="cover"
                      alt={`Header Image - ${currentImageIndex}`}
                    />
                  </motion.div>
                </AnimatePresence>

                <StyledScrollDiv>
                  <Link href={'/#mobile-app-features'} scroll={false}>
                    <StyledScrollLink
                      aria-label={
                        isDigital
                          ? t('aria_label_scroll_down')
                          : t('hc_aria_label_scroll_down')
                      }
                    >
                      <Icon name={'arrow-down'} />
                    </StyledScrollLink>
                  </Link>
                </StyledScrollDiv>
              </StyledMobileImage>
              <Text
                as={'h1'}
                variant={variant as TextTypesVariants}
                html={t(headline)}
              />
            </>

            <Text as={'p'} variant={'header-paragraph'}>
              {t(subheadline)}
            </Text>

            {button ? button : null}

            {shouldShowRating ? <HeaderRating variant="mobile" /> : null}
          </StyledHeaderWrapperContent>
        </StyledHeaderWrapper>
      </StyledHeaderContainer>
    </StyledHeaderMobile>
  )
}
