import { colors, Icon, IconTypes, Text } from 'fitify-ui'
import { useTranslation } from 'next-i18next'

import { StyledTool, StyledToolIconWrapper } from './Tools.Styled'

const Tool = ({ icon, title }: { icon: IconTypes.Variants; title: string }) => {
  const { t } = useTranslation()

  return (
    <StyledTool>
      <StyledToolIconWrapper>
        <Icon name={icon} color={'Blue400'} />
      </StyledToolIconWrapper>
      <Text
        as={'span'}
        variant={'review-content'}
        style={{
          textAlign: 'center',
          color: colors.Gray800,
          marginBottom: 'auto',
        }}
      >
        {t(title)}
      </Text>
    </StyledTool>
  )
}

export default Tool
