import { Text } from 'fitify-ui'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'

import {
  StyledMobileAppFeature,
  StyledMobileAppFeatures,
} from './MobileAppFeatures.Styled'

const MobileAppFeatures = ({
  features,
}: {
  features: { imageSrc: string; text: string }[]
}) => {
  const { t, i18n } = useTranslation()

  return (
    <Section>
      <Container>
        <StyledMobileAppFeatures id={'mobile-app-features'}>
          {features.map(({ text, imageSrc }, index) => {
            const languagePattern = /\{\{language\}\}/g
            const localizedSrc = imageSrc.replace(
              languagePattern,
              i18n.language
            )

            return (
              <StyledMobileAppFeature key={index}>
                <Image
                  src={localizedSrc}
                  width={302}
                  alt={`#${index} Human Coaching Feature`}
                  height={584}
                />
                <Text
                  variant={'newsletter-title'}
                  color={'Gray600'}
                  html={t(text)}
                />
              </StyledMobileAppFeature>
            )
          })}
        </StyledMobileAppFeatures>
      </Container>
    </Section>
  )
}

export default MobileAppFeatures
