import { colors } from 'fitify-ui'
import { StyledContainer } from 'fitify-ui-landing/src/components/Container/Container.Styled'
import {
  biggerPhone,
  tabletPortrait,
} from 'fitify-ui-landing/src/theme/breakpoints'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const StyledAnimationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 84px;
`

export const StyledAnimationImageWrapper = styled(StyledAnimationWrapper)`
  position: relative;
  top: -40vh;
  height: calc(100% + 40vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 56px;
`

export const StyledImageContainer = styled.div<{
  height: number
  width: number
}>`
  position: sticky;
  top: ${({ height }) => (100 - height) / 2}vh; // centering image with vh
  display: flex;
  align-items: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}vh;
  margin-right: 80px;

  ${tabletPortrait(css`
    margin-right: 56px;
  `)};
`

export const StyledScrollComponentContainer = styled(StyledContainer)<{
  height: number
}>`
  position: relative;
  display: flex;
  height: ${({ height }) => height}vh;

  p {
    color: ${colors.Gray600};
  }

  strong {
    color: ${colors.Black};
  }
`

export const StyledMotionImage = styled(motion.img)`
  position: absolute;
  display: flex;
  margin: 0 auto;
`

export const StyledImageWrapper = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height}vh;
`

export const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledText = styled.p`
  font-family: 'Graphik LCG Web', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;

  b {
    color: black;
  }

  ${biggerPhone(css`
    font-size: 24px;
  `)};
`
