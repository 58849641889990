import { LinkTypes } from 'fitify-ui-landing/src/@types'

const NEXT_PUBLIC_GOOGLE_PLAY_URL = process.env.NEXT_PUBLIC_GOOGLE_PLAY_URL
const NEXT_PUBLIC_APP_STORE_URL = process.env.NEXT_PUBLIC_APP_STORE_URL
const NEXT_PUBLIC_APP_GALLERY_URL = process.env.NEXT_PUBLIC_APP_GALLERY_URL

const appLinksData: LinkTypes.App[] = [
  {
    link: NEXT_PUBLIC_APP_STORE_URL as string,
    image: '/assets/images/apps/app_store.svg',
    alt: 'Download on the App Store',
    width: 117,
    height: 38,
  },
  {
    link: NEXT_PUBLIC_GOOGLE_PLAY_URL as string,
    image: '/assets/images/apps/googleplay.svg',
    alt: 'Get it on Google Play',
    width: 133,
    height: 38,
  },
  {
    link: NEXT_PUBLIC_APP_GALLERY_URL as string,
    image: '/assets/images/apps/appgallery.svg',
    alt: 'Download on AppGallery',
    width: 133,
    height: 38,
  },
]

export { appLinksData }
