const animationData: {
  imageSrc: string
  imageSrcSet: string
  text: string
}[] = [
  {
    imageSrc: `/assets/images/mobile-animation/{{language}}/1@2x.webp`,
    imageSrcSet: `/assets/images/mobile-animation/{{language}}/1.webp 600w, /assets/images/mobile-animation/{{language}}/1@2x.webp 2x, /assets/images/mobile-animation/{{language}}/1@2x.webp 1200w`,
    text: 'landing_screenshots_block_1_text',
  },
  {
    imageSrc: `/assets/images/mobile-animation/{{language}}/2@2x.webp`,
    imageSrcSet: `/assets/images/mobile-animation/{{language}}/2.webp 600w, /assets/images/mobile-animation/{{language}}/2@2x.webp 2x, /assets/images/mobile-animation/{{language}}/2@2x.webp 1200w`,
    text: 'landing_screenshots_block_2_text',
  },
  {
    imageSrc: `/assets/images/mobile-animation/{{language}}/3@2x.webp`,
    imageSrcSet: `/assets/images/mobile-animation/{{language}}/3.webp 600w, /assets/images/mobile-animation/{{language}}/3@2x.webp 2x, /assets/images/mobile-animation/{{language}}/3@2x.webp 1200w`,
    text: 'landing_screenshots_block_3_text',
  },
  {
    imageSrc: `/assets/images/mobile-animation/{{language}}/4@2x.webp`,
    imageSrcSet: `/assets/images/mobile-animation/{{language}}/4.webp 600w, /assets/images/mobile-animation/{{language}}/4@2x.webp 2x, /assets/images/mobile-animation/{{language}}/4@2x.webp 1200w`,
    text: 'landing_screenshots_block_4_text',
  },
  {
    imageSrc: `/assets/images/mobile-animation/{{language}}/5@2x.webp`,
    imageSrcSet: `/assets/images/mobile-animation/{{language}}/5.webp 600w, /assets/images/mobile-animation/{{language}}/5@2x.webp 2x, /assets/images/mobile-animation/{{language}}/5@2x.webp 1200w`,
    text: 'landing_screenshots_block_5_text',
  },
]

export { animationData }
