import { Text } from 'fitify-ui'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { ContentRevealAnimation } from 'fitify-ui-landing/src/components/animations/ContentRevealAnimation'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import NativeImage from 'fitify-ui-landing/src/components/Image/NativeImage'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import { appLinksData } from '@/data/app-links'

import {
  StyledApps,
  StyledAppsContent,
  StyledAppsLink,
  StyledAppsTitle,
} from './Apps.Styled'

const Apps = () => {
  const { i18n, t } = useTranslation()

  return (
    <Section id={ELEMENT_IDS.reviews}>
      <Container>
        <ContentRevealAnimation>
          <StyledAppsTitle>
            <Text as={'h2'} variant={'apps-title'}>
              {t('landing_emailing_block_headline')}
            </Text>
          </StyledAppsTitle>
          <StyledApps>
            <StyledAppsContent>
              {appLinksData.map((item, index: number) => {
                return (
                  <StyledAppsLink
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={item.image}
                      alt={item.alt}
                      width={item.width}
                      height={item.height}
                    />
                  </StyledAppsLink>
                )
              })}
            </StyledAppsContent>

            <NativeImage
              src={`/assets/images/apps/${i18n.language}/devices.webp`}
              srcSet={`/assets/images/apps/${i18n.language}/devices.webp 600w, /assets/images/apps/${i18n.language}/devices@2x.webp 2x, /assets/images/apps/${i18n.language}/devices@2x.webp 1200w`}
              alt={'Fitify App'}
              style={{
                height: '100%',
                width: '100%',
              }}
              width={'519'}
              height={'890'}
            />
          </StyledApps>
        </ContentRevealAnimation>
      </Container>
    </Section>
  )
}

export default Apps
