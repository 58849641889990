import { type NextRouter } from 'next/router'

const BASE_URL = 'https://gofitify.com'

// Solved canonicalUrl by https://rishimohan.me/blog/nextjs-canonical-tag
export const getCanonicalUrl = (
  path: NextRouter['asPath'],
  language?: string
): string => {
  return `${BASE_URL}${language ? `/${language}` : ''}${path === '/' ? '' : path}`.split(
    '?'
  )[0]
}
