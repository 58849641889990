import { colors, TextStyles } from 'fitify-ui'
import styled from 'styled-components'

export const StyledMobileAppFeatures = styled.div`
  flex-direction: column;
`

export const StyledMobileAppFeature = styled.div`
  &:not(:first-child) {
    margin-top: 80px;
  }

  align-items: center;
  justify-items: center;
  text-align: center;

  ${TextStyles} {
    text-align: left;
    b {
      color: ${colors.Black};
    }
  }
`
